import React, { Component } from 'react';
import { Button, Accordion, Card } from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react';
import { HashRouter as Router, Route, Switch, Link } from 'react-router-dom';
import Sidebar from "react-sidebar";
import config from '../config';
import Dashboard from '../screens/Dashboard';
import LboxList from '../screens/LboxList';
import Historial from '../screens/Historial';
import PinConfig from '../screens/PinConfig'
import Area from '../screens/Area'
import Pruebas from '../screens/Pruebas';
import AreaByVehicle from '../screens/AreaByVehicle';
import Cuentas from '../screens/Cuentas';
import InstallProcess from '../screens/InstallProcess';
import ErasedLBoxs from '../screens/ErasedLBoxs';
import CrudCommands from '../screens/CrudCommands';
import { connect } from 'react-redux';
import {
    getAccounts,
    onAccountChanged,
    onUsernameChanged,
    onPasswordChanged,
    onApiKeychanged,
    getLboxs,
    getAreasWebfleet,
    getAreaQueue,
    getPinQueue,
    getPingsLboxs
} from '../redux/actions';

const options = [
    { key: 1, text: 'Alarmados', value: "Alarmado" },
    { key: 2, text: 'Armados', value: "Armado" },
    { key: 3, text: 'Liberados', value: "Liberado" },
    { key: 4, text: 'Todos', value: "todos" }
]

const APP_VERSION = 'V 0.1.15';
class Main extends Component {
    state = {
        showMenu: false,
        account: "",
        installer: false,
        defaultAccount: null,
        accounts: [],
        loading: false
    }
    async componentWillMount() {

        let token = await sessionStorage.getItem('token');
        let user = JSON.parse(token);

        if (user) {
            let defAccount = {
                text: user.account,
                value: {
                    account: user.account,
                    username: user.username,
                    password: user.password,
                    apiKey: user.apikey
                }

            }

            await this.props.getAccounts(user.account, user.username, user.password);

            this.setState({ account: user.account, installer: user.installer, defaultAccount: defAccount, accounts: this.props.accounts })

        } else {
            this.props.history.push('/')
        }

    }
    onSetSidebarOpen = (open) => {
        this.setState({ showMenu: open });
    }

    logout = () => {
        localStorage.clear()
        sessionStorage.removeItem('token')
        this.props.history.push('/')
    }
    async onChangeAccount(value) {
        this.setState({ loading: true });
        await sessionStorage.clear();
        await sessionStorage.setItem('token', JSON.stringify(value))

        await this.props.onAccountChanged(value.account);
        await this.props.onUsernameChanged(value.username);
        await this.props.onPasswordChanged(value.password);
        await this.props.onApiKeychanged(value.apiKey);

        const { account, username, password } = this.props;
        await this.props.getLboxs(account, username, password);
        await this.props.getAreasWebfleet(account, username, password);
        await this.props.getAreaQueue(account, username, password);
        await this.props.getPingsLboxs(account, username, password);
        await this.props.getPinQueue(account, username, password);
        this.setState({ loading: false, defaultAccount: value });


    }
    renderSideBarContent() {

        if (this.state.installer === "true") {
            return (
                <div style={styles.sidebarContainer}>
                    <div style={styles.logoSection}>
                        <img alt='' style={styles.logo} src={'http://168.235.83.246/images/LBox_logo.png'} />
                        <span style={styles.versionText}>{APP_VERSION}</span>
                    </div>
                    <Button onClick={() => this.props.history.push('/application/dashboard')} style={styles.sidebarButton} variant='light'>Tablero de control</Button>
                    <Button onClick={() => this.props.history.push('/application/lboxlist')} style={styles.sidebarButton} variant='light'>Lista de LBox's</Button>
                    <Button onClick={() => this.props.history.push('/application/historial:')} style={styles.sidebarButton} variant='light'>Historial</Button>
                    <Button onClick={() => this.props.history.push('/application/pin-config')} style={styles.sidebarButton} variant='light'>Código de seguridad</Button>
                    <Button onClick={() => this.props.history.push('/application/areas')} style={styles.sidebarButton} variant='light'>Añadir geocercas</Button>
                    <Button onClick={() => this.props.history.push('/application/pruebas')} style={styles.sidebarButton} variant='light'>Comandos</Button>
                    <Button onClick={() => this.props.history.push('/application/areasByVehicle')} style={styles.sidebarButton} variant='light'>Geocercas en vehículos</Button>
                    <Button onClick={() => this.props.history.push('/application/cuentas')} style={styles.sidebarButton} variant='light'>Cuentas</Button>
                    <Button onClick={() => this.props.history.push('/application/recuperarLbox')} style={styles.sidebarButton} variant='light'>Recuperar LBox's</Button>
                    <Button onClick={() => this.props.history.push('/application/instalacion')} style={styles.sidebarButton} variant='light'>Instalación</Button>
                    <Button onClick={() => this.props.history.push('/application/comando')} style={styles.sidebarButton} variant='light'>Comandos por texto</Button>
                    <Button onClick={() => window.open('http://168.235.83.246/guiaDeUso_Portal-LBox.pdf')} style={styles.sidebarButton} variant='light'>Guía de uso</Button>


                    <div style={styles.lastButtonContainer}>
                        <Button style={styles.sidebarButton} onClick={(e) => { if (window.confirm('¿Desea cerrar sesión?')) this.logout() }} variant='light'>Salir</Button>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div style={styles.sidebarContainer}>
                    <div style={styles.logoSection}>
                        <img alt='' style={styles.logo} src={'http://168.235.83.246/images/LBox_logo.png'} />
                        <span style={styles.versionText}>{APP_VERSION}</span>
                    </div>
                    <Button onClick={() => this.props.history.push('/application/dashboard')} style={styles.sidebarButton} variant='light'>Tablero de control</Button>
                    <Button onClick={() => this.props.history.push('/application/lboxlist')} style={styles.sidebarButton} variant='light'>Lista de LBox's</Button>
                    <Button onClick={() => this.props.history.push('/application/historial:')} style={styles.sidebarButton} variant='light'>Historial</Button>
                    <Button onClick={() => this.props.history.push('/application/pin-config')} style={styles.sidebarButton} variant='light'>Código de seguridad</Button>
                    <Button onClick={() => this.props.history.push('/application/areas')} style={styles.sidebarButton} variant='light'>Añadir geocercas</Button>
                    <Button onClick={() => this.props.history.push('/application/areasByVehicle')} style={styles.sidebarButton} variant='light'>Geocercas en vehículos</Button>
                    <Button onClick={() => window.open('http://168.235.83.246/guiaDeUso_Portal-LBox.pdf')} style={styles.sidebarButton} variant='light'>Guía de uso</Button>

                    <div style={styles.lastButtonContainer}>
                        <Button style={styles.sidebarButton} onClick={(e) => { if (window.confirm('¿Desea cerrar sesión?')) this.logout() }} variant='light'>Cerrar sesión</Button>
                    </div>
                </div>
            );
        }
    }

    render() {
        if (this.state.installer === "true") {
            return (
                <Router>
                    <div>
                        <Sidebar sidebar={this.renderSideBarContent()} styles={{ sidebar: styles.sidebar }} onSetOpen={this.onSetSidebarOpen} open={this.state.showMenu}>
                            <div style={styles.navbar}>
                                <div style={{ display: 'flex', flexDirection: 'row' }} >
                                    <img alt='' src={'http://168.235.83.246/images/menu.png'} style={styles.menuIcon} onClick={() => this.setState({ showMenu: !this.state.showMenu })} />
                                    <img alt='' style={styles.lboxLogo} src={'http://168.235.83.246/images/lboxBlanco.png'} />
                                </div>
                                <Dropdown loading={this.state.loading} fluid search selection options={this.state.accounts} defaultValue={this.state.defaultAccount.value} style={{ backgroundColor: 'transparent', color: 'white', width: 250 }} onChange={(e, data) => this.onChangeAccount(data.value)} />
                            </div>

                            <Route path='/application/dashboard' component={Dashboard} />
                            <Route path='/application/lboxlist' component={LboxList} />
                            <Route path='/application/historial:lbox' component={Historial} />
                            <Route path='/application/pin-config' component={PinConfig} />
                            <Route path='/application/areas' component={Area} />
                            <Route path='/application/pruebas' component={Pruebas} />
                            <Route path='/application/areasByVehicle' component={AreaByVehicle} />
                            <Route path='/application/cuentas' component={Cuentas} />
                            <Route path='/application/instalacion' component={InstallProcess} />
                            <Route path='/application/recuperarLbox' component={ErasedLBoxs} />
                            <Route path='/application/comando' component={CrudCommands} />
                        </Sidebar>
                    </div>
                </Router>
            )
        }
        else {
            return (
                <Router>
                    <div>
                        <Sidebar sidebar={this.renderSideBarContent()} styles={{ sidebar: styles.sidebar }} onSetOpen={this.onSetSidebarOpen} open={this.state.showMenu}>
                            <div style={styles.navbar}>
                                <div style={{ display: 'flex', flexDirection: 'row' }} >
                                    <img alt='' src={'http://168.235.83.246/images/menu.png'} style={styles.menuIcon} onClick={() => this.setState({ showMenu: !this.state.showMenu })} />
                                    <img alt='' style={styles.lboxLogo} src={'http://168.235.83.246/images/lboxBlanco.png'} />
                                </div>
                                <span style={{ color: 'white', fontSize: 18, alignSelf: 'flex-end', display: 'flex', width: '40%', justifyContent: 'flex-end' }} >{this.state.account}</span>
                            </div>

                            <Route path='/application/dashboard' component={Dashboard} />
                            <Route path='/application/lboxlist' component={LboxList} />
                            <Route path='/application/historial:lbox' component={Historial} />
                            <Route path='/application/pin-config' component={PinConfig} />
                            <Route path='/application/areas' component={Area} />
                            <Route path='/application/areasByVehicle' component={AreaByVehicle} />




                        </Sidebar>
                    </div>
                </Router>
            )
        }

    }

}

const styles = {
    lboxLogo: {
        width: '8%',
        minWidth: 50,
        marginLeft: 15
    },
    sidebar: {
        backgroundColor: 'white',
        display: 'flex',
        flex: 1,
        minHeight: '100vh'
    },
    navbar: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        backgroundColor: '#454955',
        alignItems: 'center',
        padding: 15,
        justifyContent: 'space-between'
    },
    menuIcon: {
        height: 25,
        width: 25
    },
    navbarTitle: {
        marginLeft: 20,
        fontSize: 25,
        color: 'white'
    },
    sidebarContainer: {
        display: 'flex',
        width: '30%',
        flexDirection: 'column'
    },
    logoSection: {
        display: 'flex',
        width: 250,
        height: '15%',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: 20,
        marginBottom: 15,
        padding: 10
    },
    logo: {
        width: '70%',
        display: 'flex'
    },
    versionText: {
        alignSelf: 'flex-end',
        fontSize: 13,
        fontWeight: 'bold'
    },
    sidebarButton: {
        width: 250,
        borderRadius: 0,
        height: 50,
        borderColor: '#9bb800',
        borderLeftWidth: 0,
        borderRightWidth: 0
    },
    sidebarButtonCollapse: {
        width: 250,
        borderRadius: 0,
        height: 50,
        borderColor: '#9bb800',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        justifyContent: 'flex-end',
    },
    lastButtonContainer: {
        width: '100%',
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'flex-end',
    }

}
const mapStateToProps = state => {
    return {
        account: state.auth.account,
        password: state.auth.password,
        username: state.auth.username,
        apipkey: state.auth.apiKey,
        message: state.auth.message,
        accounts: state.auth.accounts,
        lboxs: state.lbox.lboxs
    };
}
export default connect(mapStateToProps, {
    getAccounts,
    onAccountChanged,
    onUsernameChanged,
    onPasswordChanged,
    onApiKeychanged,
    getLboxs,
    getAreasWebfleet,
    getAreaQueue,
    getPinQueue,
    getPingsLboxs
})(Main);
